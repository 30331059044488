<template>
  <div v-if="APPLICATION_ID" class="content">
    <ApplicationNannyInputRecommendations
      :recommendations="recommendations"
      @create="handleCreate"
      @update="handleUpdate"
      @delete="handleDelete"
      @update:mode="mode = $event"
    />

    <ButtonLink
      v-if="recommendations.length === 0 && mode === VIEW_MODES.READ"
      :to="ROUTE_APPLICATION_NANNY_CREATE_FINISH"
      :label="'Może pózniej'"
      class="button-later"
    />

    <ButtonBasicNext
      v-else-if="recommendations.length > 0 && mode === VIEW_MODES.READ"
      :to="ROUTE_APPLICATION_NANNY_CREATE_FINISH"
      class="button-next"
    />
  </div>
</template>

<script>
import ApplicationNannyInputRecommendations, {
  VIEW_MODES,
} from "@/components/Application/Nanny/ApplicationNannyInputRecommendations.vue";
import ButtonBasicNext from "@/components/UI/ButtonBasicNext.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

import { useRouter } from "vue-router";
import { useApplicationNannyStore } from "@/stores/applicationNanny";
import {
  ROUTE_APPLICATION_NANNY_CREATE_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_FINISH_NAME,
} from "@/router/constants";
import useApplicationNannyRecommendations from "@/composables/useApplicationNannyRecommendations";

export default {
  components: {
    ApplicationNannyInputRecommendations,
    ButtonBasicNext,
    ButtonLink,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();
    const APPLICATION_ID = applicationNanny._id;
    const ROUTE_APPLICATION_NANNY_CREATE_FINISH = {
      name: ROUTE_APPLICATION_NANNY_CREATE_FINISH_NAME,
    };

    const router = useRouter();

    // GUARD -> redirect to the Start if no ID provided
    if (APPLICATION_ID === null) {
      console.log("1");
      router.push({ name: ROUTE_APPLICATION_NANNY_CREATE_NAME });
      return { APPLICATION_ID };
    }

    const {
      recommendations,
      isLoading,
      mode,
      handleCreate,
      handleUpdate,
      handleDelete,
    } = useApplicationNannyRecommendations(APPLICATION_ID);

    return {
      APPLICATION_ID,
      ROUTE_APPLICATION_NANNY_CREATE_FINISH,
      VIEW_MODES,

      recommendations,
      isLoading,
      mode,

      handleCreate,
      handleUpdate,
      handleDelete,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
}
.button-later {
  margin-top: 30px;
}
.button-next {
  margin-top: 100px;
}
</style>
